<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="arrPaginationData.page"
    :page-sizes="[10, 20, 50, 100]"
    :page-size="arrPaginationData.limit"
    layout="total, sizes, prev, pager, next, jumper"
    :total="arrPaginationData.total"
    prev-text="上一页"
    next-text="下一页"
    style="margin-top: 20px; display: flex; justify-content: flex-end"
  >
  </el-pagination>
</template>

<script>
export default {
  props: {
    arrPaginationData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  methods: {
    //监听分页总条数改变
    handleSizeChange(newLimit) {
      this.arrPaginationData.limit = newLimit;
      this.$emit("handleSizeChange", newLimit);
    },
    //监听页码值改变
    handleCurrentChange(newPage) {
      this.arrPaginationData.page = newPage;
      this.$emit("handleCurrentChange", newPage);
    },
  },
};
</script>

<style>
</style>