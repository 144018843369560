<template>
	<div style="padding: 20px 40px;">
		<el-card>
			<div style="text-align: center;">
				<div style="display: inline-block;" v-for="(items,index) in arrMedia">
					<div style="display: flex; flex-direction: column;">
						<div>
							<el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
								:show-file-list="false" :on-success="handleAvatarSuccess">
								<img v-if="arrInfo.head_img" :src="arrInfo.head_img" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</div>
						<div>
							<span style="color: #999;font-size: 12px;line-height: 20px;">{{items.label}}</span>
						</div>
					</div>
				</div>
			</div>
			<el-descriptions title="编辑用户信息" border :column="2">
				<template slot="extra">
					<el-button type="text" :disabled="disabled" @click="submit">保存</el-button>
					<el-button type="text" @click="disabledCheck">{{actionText}}</el-button>
				</template>
				<template v-for="(item,index) in arrForm" :prop="item.field">
					<!-- 文本框 -->
					<el-descriptions-item :label="item.label" v-if="item.type == 'input' || item.type == 'textarea'">
						<el-input class="inputDeep" :placeholder="'请输入' + item.label" :type="item.type"
							v-model="arrInfo[item.field]" :disabled="disabled || item.disabled"> </el-input>
					</el-descriptions-item>
					<!-- 下拉框 -->
					<el-descriptions-item :label="item.label" v-else-if="item.type == 'select' ">
						<el-select :placeholder="'请选择' + item.label" v-model="arrInfo[item.field]" class="select-veido"
							:disabled="disabled || item.disabled">
							<el-option v-for="i in item.lists" :key="i.id" :label="i.name" :value="i.id"></el-option>
						</el-select>
					</el-descriptions-item>
					<!-- 枚举值 -->
					<el-descriptions-item :label="item.label" v-else-if="item.type == 'enum' ">
						<div v-for="(vals,keys) in item.lists" :key="keys">
							<div v-if="vals.value == arrInfo[item.field]" style="padding: 0 15px; display: flex;">
								<div>
									<span class="tag" :style="{'background-color': vals.color}"></span>
									<span :style="{'color': disabled ? '#C0C4CC':'#606266'}">{{vals.text}}</span>
								</div>

								<el-popover style="display: inline-block; margin-left: auto;" v-if="!disabled"
									placement="bottom" trigger="click" width="150">
									<template v-for="(enumVals,enumKeys) in item.lists">
										<el-radio v-model="arrInfo[item.field]" :label="enumVals.value">
											<span class="tag"
												:style="{'background-color': enumVals.color}"></span>{{enumVals.text}}
										</el-radio>
									</template>

									<div slot="reference">
										<span style="color:#C0C4CC;cursor:pointer">修改</span>
									</div>
								</el-popover>
							</div>
						</div>
					</el-descriptions-item>
				</template>
			</el-descriptions>
		</el-card>
	</div>
</template>

<script>

	import oJs from "@/js/config.js";
	export default {
		props: {
			sUrl: { //请求地址
				type: String,
				default: '',
			},
			sRow: { //当前请求的一行的数据
				type: Object,
				default: {},
			},
			infoType: {
				type: String,
				default: '',
			}
		},
		data() {
			return {
				actionText: '', // 按钮内容
				disabled: false, // 是否允许修改
				arrForm: [], // 表单内容
				arrInfo: {}, // 详情内容
				arrEdit: {}, // 临时保存编辑的内容
				arrMedia: [], //媒体素材内容
			}
		},
		created() {
			this.initPage();
			this.actionText = '清空'
			if (this.infoType == 'edit') {
				this.disabled = true;
				this.actionText = '编辑'
				this.getInfo();
			}
		},

		methods: {
			// 编辑按钮检测
			disabledCheck(key) {
				if (!this.disabled) {
					this.arrInfo = this.arrEdit;
				} else {
					this.arrEdit = JSON.parse(JSON.stringify(this.arrInfo))
				}
				this.disabled = !this.disabled;
				this.actionText = this.actionText == '编辑' ? '取消' : '编辑';
			},
			// 提交数据
			submit() {
				let data = {
					id: this.sRow.id,
				};
				for (var i = 0; i < this.arrForm.length; i++) {
					// 无法修改字段过滤
					if (this.arrForm[i].disabled == true) {
						continue;
					}
					var field = this.arrForm[i].field
					// 必填字段验证
					if (this.arrForm[i].is_null == false) {
						if (this.arrInfo[field] === null) {
							this.$message.error(this.arrForm[i].label + '必填');
							return;
						}
						if (this.arrInfo[field].length <= 0) {
							this.$message.error(this.arrForm[i].label + '必填');
							return;
						}
					}
					data[field] = (this.arrInfo[field]);
				}
				// for (var i = 0; i < this.arrMedia.length; i++) {
					// 无法修改字段过滤
					// if (this.arrMedia[i].disabled == true){
					// 	continue;
					// }
					// var field = this.arrMedia[i].field
					// // 必填字段验证
					// if (this.arrMedia[i].is_null == false){
					// 	if (this.arrInfo[field] === null) {
					// 		this.$message.error(this.arrMedia[i].label+'必填');
					// 		return;
					// 	}
					// 	if (this.arrInfo[field].length <= 0) {
					// 		this.$message.error(this.arrMedia[i].label+'必填');
					// 		return;
					// 	}	
					// }
					// data[field] = (this.arrInfo[field]);
				// }
				this.submitInfo(data);

			},
			initPage() {
				let data = {
					type: 'form_' + this.infoType,
				};
				oJs.initPage(data, this.sUrl).then((res) => {
					// 添加页面数据处理
					if (this.infoType == 'add') {
						for (var i = 0; i < res['form'].length; i++) {
							this.arrInfo[res['form'][i].field] = res['form'][i].default
						}
						if (res['media']) {
							for (var i = 0; i < res['media'].length; i++) {
								this.arrInfo[res['media'][i].field] = res['media'][i].default
							}
						}

						this.arrInfo = JSON.parse(JSON.stringify(this.arrInfo))
					}
					this.arrForm = res.form
					this.arrMedia = res.media
				});

			},
			getInfo() {
				let data = {
					id: this.sRow.id,
				};
				oJs.getInfo(data, this.sUrl).then((res) => {
					this.arrInfo = res
				});
			},
			/**
			 * 提交更改
			 * @param {Object} arrInfo 要保存的信息
			 */
			submitInfo(arrInfo) {
				arrInfo.form_type = this.infoType;
				oJs.saveData(arrInfo, this.sUrl).then((res) => {
					console.log(res)
				})
			},
			/**
			 * 图片上传成功事件
			 */
			handleAvatarSuccess(res, file) {
				console.log(URL.createObjectURL(file.raw));
				// this.arrInfo.background_img_path = ;
			},
		}
	}
</script>

<style>
	.inputDeep input.el-input__inner {
		border: 0;
		/* border-bottom: 1px solid #DCDFE6; */
		border-radius: 0;
	}

	/* .inputDeep input.el-input.is-disabled */

	.el-input.is-disabled .el-input__inner {
		cursor: default;
		background-color: transparent;
	}

	.el-select {
		display: block;
	}

	.select-veido input.el-input__inner {
		width: 100%;
		color: #606266;
		background: none;
		border: 0;

		border-radius: 0;
	}

	.tag {
		position: relative;
		top: -1px;
		display: inline-block;
		width: 6px;
		height: 6px;
		vertical-align: middle;
		border-radius: 50%;
		margin-right: 6px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 120px;
		height: 120px;
		line-height: 120px;
		text-align: center;
	}

	.avatar {
		width: 120px;
		height: 120px;
		display: block;
	}
</style>
