<template>
	<el-card style="padding: 2px 0;">
		<el-form ref="form" :model="arrSearchForm" label-width="80px">
			<el-row :gutter="20" type="flex" class="row-bg" justify="center">
				<el-col :span="3">
					<el-input v-model="arrSearchForm.word" placeholder="搜索关键字" size="small"></el-input>
				</el-col>

				<el-col :span="3">
					<el-select v-model="arrSearchForm.field" placeholder="" size="small">
						<el-option label="请选择" value="0"></el-option>
						<el-option :label="val" :value="key" v-for="(val, key) in arrSearchData" :key="key"></el-option>
					</el-select>
				</el-col>

				<el-col :span="3">
					<el-select v-model="arrSearchForm.methord" size="small">
						<el-option label="模糊搜索" value="like"></el-option>
						<el-option label="精准搜索" value="1"></el-option>
					</el-select>
				</el-col>

				<el-col :span="3">
					<el-date-picker size="small" v-model="arrSearchForm.startTime" type="date" style="width:100%"
						placeholder="开始时间" value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-col>
				<el-col :span="3">
					<el-date-picker size="small" v-model="arrSearchForm.endTime" type="date" placeholder="结束时间"
						value-format="yyyy-MM-dd" style="width:100%">
					</el-date-picker>
				</el-col>
				<el-col :span="2" :push="0">
					<el-button type="success" size="small" @click="search">搜索</el-button>
				</el-col>
				
			</el-row>
		</el-form>
	</el-card>

</template>

<script>
	export default {
		props: {
			arrSearchData: {
				type: Object,
				default: {},
			},
		},
		data() {
			return {
				arrSearchForm: {
					word: '', //关键字
					field: "0", //搜索的字段
					methord: 'like', //模糊 精准搜索
					startTime: '', //开始时间
					endTime: '', //结束时间
				},
			}
		},
		methods: {
			search() {
				let nWord = this.arrSearchForm.word.replace(/^\s*|\s*$/g, "")
				if (String(nWord).length <= 0 && this.arrSearchForm.field == '0') {
					this.$emit("search", this.arrSearchForm);
				}
				if (String(nWord).length > 0 && this.arrSearchForm.field == '0') {
					this.$message.error('请选择搜索条件');
					return false;
				}
				this.$emit("search", this.arrSearchForm);

			},
		},
	};
</script>

<style>

</style>
