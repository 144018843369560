<template>
    <div>
        <!-- 搜索开始 -->
        <Search @search="search" :arrSearchData="arrSearchData"></Search>
        <!-- 搜索结束 -->
        <el-card class="box-card" style="margin-top: 10px;">
            <!-- 列表开始 -->
            <!-- 按钮 -->
            <el-row style="margin-bottom: 5px;">
                <el-button v-for="(val,key) in oButton" :key="key" :type="val.type" size="small" style="margint:0 2px;" @click="buttonClick(val.action)">{{val.name}}</el-button>
                <i class="iconfont icon-exchangerate" style="" @click="refresh"></i>
            </el-row>
            <div>
				<el-table :data="arrListData" stripe style="width: 100%" height="66vh" v-loading="loading" size="mini"
					:header-cell-style="{background:'#f5f8fc',color:'#606266'}" @sort-change="sortChange"
					@selection-change="handleSelectionChange"  @filter-change="filterChange">
					<!-- 多选控件 -->
					<el-table-column type="selection" width="55" align="center">
					</el-table-column>
					<!-- 行索引 重定义宽度 -->
					<el-table-column label="序号" align="center" width="46">
						<template slot-scope="scope">
							<p>{{scope.row['page_index']}}</p>
						</template>
					</el-table-column>
					<!-- 自动生成页面 -->
					<el-table-column :sortable="val.sort" :filters="getFilters(key)" :column-key="key" :prop="key" :label="val.title" align="center"
						v-for="(val,key) in oTableField" :key="key" v-if="key != 'page_index' && key != 'expand'">
						<template slot-scope="scope">
							<!-- 文字 -->
							<p v-if="val.type == 'text'">{{scope.row[key]}}</p>
							<!-- 图片 -->
							<el-image v-if="val.type == 'img'" style="width: 50px; height: 50px" :src="scope.row[key]"
								:preview-src-list="[scope.row[key]]">
								
							</el-image>
							<!-- 枚举 -->
							<div v-if="val.type == 'enum'">
								<div v-for="(vals,keys) in val.values" :key="keys">
									<div v-if="vals.value == scope.row[key]">
										<span class="tag" :style="{'background-color': vals.color}"></span>{{vals.text}}
									</div>
								</div>
							</div>
							<!-- HTML -->
							<p v-if="val.type == 'html'" v-html="scope.row[key]"></p>
						</template>
					</el-table-column>
					<!-- 行内按钮 -->
					<el-table-column label="操作" align="center" :width="actionWidth">
						<template slot-scope="scope" v-if="scope.row.button.length">
							<el-button v-for="(buttonItem,buttonKey) in scope.row.button" :type="buttonItem.type" size="mini" @click="itemButtonClick(buttonItem.action, scope.row)">{{buttonItem.name}}
							</el-button>
						</template>
					</el-table-column>	
				</el-table>
				
             </div>
            <!-- 列表结束 -->
            <!-- 分页开始 -->
            <Pagination :arrPaginationData="arrPaginationData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
            <!-- 分页结束 -->
        </el-card>
		<!-- 详情页抽屉 -->
		<el-drawer
			:visible.sync="showInfoDrawer"
			direction="rtl"
			size="65%"
			@closed="refresh()">
		  <PageInfo :sUrl="sUrl" :sRow="sRow" :infoType="infoType" v-if="showInfoDrawer"></PageInfo>
		</el-drawer>
    </div>
</template>

<script>
    import oConfig from "@/js/config.js";
    import Search from "@/components/search.vue"; //搜索组件
    import Pagination from "@/components/pagination.vue"; //分页组件
	import PageInfo from "@/components/info.vue"; //分页组件

    export default {
        props: {
            sUrl: {     //请求地址
                type: String,
                default: '',
            },
        },
        components: {
            Search,
            Pagination,
			PageInfo
        },
        data() {
            return {
                loading: true, //页面加载
                //搜索字段数据
                arrSearchData: {},
                //搜索表单
                arrSearchForm: {},
                // 面包屑
                arrBreadData: {
                    title: "活动管理",
                    children: [{
                        title: "活动管理列表",
                    }, ],
                },
                //分页
                arrPaginationData: {
                    page: 1, //当前页码
                    limit: 10, //一页几条
                    total: 0, //总条数
                    order: '', // 排序字段
                    type: '', // 排序规则
					filter: [], // 过滤的字段
                },
                //列表数据
                arrListData: [],
                oTableField: {}, //列表标题
                oButton: {}, //按钮
				filters:[], // 过滤列表
				ids: [], // 多选的ids
				actionWidth:0, // 操作栏的宽度
				showInfoDrawer: false, // 是否展示详情页抽屉
				sRow:{}, // 当前选择的行
				infoType:'',// 详情页类型
            };
        },
        created() {
            this.bread();
            this.initPageApi();
            this.getListApi()
        },
        methods: {
            //搜索组件传值
            Search(searchForm) {
                this.arrSearchForm = searchForm;
            },
            /**
             * @param {Object} data
             * 搜索
             */
            search(data) {
                this.loading = true;
                this.getListApi(data);
            },

            //面包屑传值
            bread() {
                this.$emit("bread", this.arrBreadData);
            },
            //分页-监听分页总条数改变
            handleSizeChange(newLimit) {
                this.arrPaginationData.limit = newLimit;
                this.getListApi();
            },
            //分页-监听页码值改变
            handleCurrentChange(newPage) {
                this.arrPaginationData.page = newPage;
                this.getListApi();
            },
            /**
             * 上侧按钮
             */
            buttonClick(action) {
				if(action !== 'addData'){
					 this.$emit('buttonMethods', action, this.ids);
				}else{
					this.addData(this.ids);
				}
            },
			/**
			 * 行内按钮
			 * @param {Object} action
			 * @param {Object} param
			 */
			itemButtonClick(action, param){
				if(action !== 'getInfo'){
					this.$emit('itemButtonMethods',action,param);  
				}else{
					this.getInfo(param);
				}
				
			},
            /**
             * 刷新
             */
            refresh() {
                this.loading = true;
                this.getListApi();
            },
            //获取列表
            initPageApi() {
                let data = {
                    type: 'table',
                };
                oConfig.initPage(data,this.sUrl).then((res) => {
					if(res.search_field.constructor == Object){
						this.arrSearchData = res.search_field;
					}
                   
                    this.oTableField = res.table_field;
                    this.oButton = res.button;
					this.filters = res.filters

                });
            },

            //获取列表
            getListApi(oSearch = {}) {
                let data = {
                    page: this.arrPaginationData.page - 1,
                    limit: this.arrPaginationData.limit,
                    word: oSearch.word,
                    field: oSearch.field,
                    methord: oSearch.methord,
                    startTime: oSearch.startTime,
                    endTime: oSearch.endTime,
                    order: this.arrPaginationData.order,
                    type: this.arrPaginationData.type,
					filter :this.arrPaginationData.filter,
					param:this.$route.query,
                };
                oConfig.getList(data,this.sUrl).then((res) => {
                    this.arrPaginationData.total = res.total;
                    this.arrListData = res.data;
                    this.loading = false;
					var that = this
					res.data.forEach((item,key) => {
						let nWidth = item.button.length*120
						if (nWidth > that.actionWidth) {
							that.actionWidth = nWidth
						}
					})
                });
            },


            // 修改排序
            sortChange(column) {
                this.arrPaginationData.order = column.prop;
                if (column.order == 'ascending') {
                    this.arrPaginationData.type = 'asc';
                } else if (column.order == 'descending') {
                    this.arrPaginationData.type = 'desc';
                } else {
                    this.arrPaginationData.order = '';
                    this.arrPaginationData.type = '';
                }
                this.getListApi()
                console.log(column)
            },
			handleSelectionChange(row) {
				this.ids = [];
				if (row.length > 0) {
					row.forEach((item, key) => {
						this.ids.push(item.id);
					})
				}
				console.log(this.ids)
			},
			// 获取过滤框的条件
			getFilters(key){
				// console.log(this.filters);debugger
				if(this.filters[key]){
					return this.filters[key]
				}
				return 
			},
			// 字段值过滤
			filterChange(column){
				this.arrPaginationData.filter = [];
				for(let key in column){
					this.arrPaginationData.filter.push({key:key,column:column[key]})
				}
				this.getListApi()
			},
			
			getInfo(param){
				this.infoType = 'edit'
				this.sRow = param
				this.showInfoDrawer = true;
				console.log('组件组件组件！！！')
			},

			addData(param){
				this.infoType = 'add'
				this.showInfoDrawer = true;
				console.log('组件组件组件！！！')
			},

        },
    };
</script>

<style lang="less" scoped>
    .el-breadcrumb {
        margin-bottom: 15px;
        // font-size: 12px;
    }


    .icon-exchangerate {
        font-size: 30px;
        float: right;
        text-align: center;
    }

    .icon-exchangerate:hover {
        font-size: 32px;
        // color: #00FFFF;
    }

    .demo-table-expand {
        font-size: 0;
    }

    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }

    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 100%;
    }
	
	.tag {
		position: relative;
		top: -1px;
		display: inline-block;
		width: 6px;
		height: 6px;
		vertical-align: middle;
		border-radius: 50%;
		margin-right: 6px;
	}
	.el-breadcrumb {
		margin-bottom: 15px;
		// font-size: 12px;
	}
	
	
	.icon-exchangerate {
		font-size: 32px;
		float: right;
		text-align: center;
	}
	
	.icon-exchangerate:hover {
		color: #1890FF;
	}
</style>